<template>
  <div clas="container">
    <h2>{{ $t("register.title") }}</h2>
    <div class="flix-form-group">
      <a
        href="#"
        class="flix-html-a flix-text-darkgrey"
        @click.prevent="callback('signIn')"
        >{{ $t("register.isRegistred") }}</a
      >
    </div>
    <div class="flix-form-group">
      <inputText
        :error="getError('username')"
        :label="$t('register.username.title')"
        :placeholder="$t('register.username.title')"
        :data="username"
        :callback="function (ret) { setUsername(ret.toLowerCase().trim()) }"
      />
      <div class="flix-form-group">
        <small class="flix-small flix-html-strong">{{
          $t("register.username.info")
        }}</small>
      </div>
      <inputEmail
        :error="getError('email')"
        :label="$t('register.email.title')"
        :placeholder="$t('register.email.title')"
        :data="email"
        :callback="setEmail"
      />
      <inputPassword
        :error="getError('pass')"
        :label="$t('register.password.title')"
        :placeholder="$t('register.password.title')"
        :data="pass"
        :callback="setPassword"
      />
      <terms :send="send" />
    </div>
    <saveBtn
      class="saveBtn flix-form-group"
      :label="$t('register.button')"
      :callback="function () { setSave(); }"
    />
    <div
      v-html="state"
      class="flix-text-danger flix-html-strong flix-html-small"
      v-if="state"
    />
  </div>
</template>
<script>
export default {
  components: {
    terms() {
      return import("./terms");
    }
  },
  props: {
    callback: Function
  },
  data() {
    return {
      username: this.getUsername(),
      email: this.getEmail(),
      pass: "",
      send: false,
      state: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getUsername() {
      if (
        typeof this.$store.state.save.userData === "undefined" ||
        !this.$store.state.save.userData
      ) {
        return "";
      }
      if (typeof this.$store.state.save.userData.username === "string") {
        return this.$store.state.save.userData.username;
      }
    },
    getEmail() {
      if (
        typeof this.$store.state.save.userData === "undefined" ||
        !this.$store.state.save.userData
      ) {
        return "";
      }
      if (typeof this.$store.state.save.userData.email === "string") {
        return this.$store.state.save.userData.email;
      }
    },
    getError(type) {
      if (!this.send) {
        return false;
      }
      if (!this[type]) {
        if (type === "email") {
          return this.$t("register.email.warning");
        }
        if (type === "username") {
          return this.$t("register.username.warning");
        }
        if (type === "pass") {
          return this.$t("register.password.warning");
        }
      }
    },
    setEmail(val) {
      this.email = val;
      this.error = false;
    },
    setPassword(val) {
      this.pass = val;
      this.error = false;
    },
    setUsername(val) {
      this.username = val
        .toLowerCase()
        .trim()
        .split("ä")
        .join("ae")
        .split("ö")
        .join("oe")
        .split("ü")
        .join("ue")
        .split("ß")
        .join("ss")
        .replace(/[^\w\s]/gi, "");
      this.error = false;
    },
    setSave() {
      this.send = true;
      this.username = this.username
        .toLowerCase()
        .trim()
        .split("ä")
        .join("ae")
        .split("ö")
        .join("oe")
        .split("ü")
        .join("ue")
        .split("ß")
        .join("ss")
        .replace(/[^\w\s]/gi, "");
      this.email = this.email.toLowerCase().trim();
      if (!this.username) {
        return false;
      }
      if (!this.email) {
        return false;
      }
      if (!this.pass) {
        return false;
      }

      this.$store.commit("save/setUser", {
        username: this.username,
        email: this.email,
        pass: this.pass
      });
      this.$store.dispatch(
        "save/setRegister",
        function(success, state) {
          if (!success) {
            this.state = state;
          } else {
            this.$store.commit("business/update", { ID: state });
            this.$store.commit("save/setSuccess");
            if (typeof window.flix_quickstart === "function") {
              window.flix_quickstart(
                this.$store.state.business.assistent,
                this.$store.state.service.services
              );
            }
          }
        }.bind(this)
      );
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.container
  a.btn
    display: block
    font-size: 10pt
    text-align: center
    margin-bottom: 10px
    color: $dark-grey
  a.saveBtn
    display: flex
  h3
    text-align: center
</style>
